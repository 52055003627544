import Swiper, { EffectFade, Autoplay} from 'swiper';
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

(function() {
  AOS.init({
    once: true,
  });
  const swiperOptions = {
    modules: [Autoplay, EffectFade],
    autoplay: true,
    speed: 2000,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  }
  
  const heroSwiper = new Swiper(".js-hero-swiper", swiperOptions);
  const producSwiper = new Swiper(".js-product-swiper", swiperOptions);

  let windowWidth = window.innerWidth;
  
  window.addEventListener('resize', handleWindowWidth);

  function handleWindowWidth() {
    windowWidth = window.innerWidth;
    return windowWidth
  }

  const toggleOptions = {
    menu: document.querySelectorAll('button.js-nav-toggle'),
    item: document.querySelectorAll('.nav__menu a'),
  }

  function toggleNavItems() {
    if(windowWidth < 1024) {
      if (document.querySelector('.nav__toggle').classList.contains('active')) {
        console.log('if')
        document.querySelector('.nav__toggle').classList.remove('active');
        document.querySelector('.nav__menu').classList.remove('active');
      } else {
        document.querySelector('.nav__toggle').classList.add('active');
        document.querySelector('.nav__menu').classList.add('active');
      }
    } else {
      return
    }
  }
  
  toggleOptions.menu.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault();
      toggleNavItems()
    });
  });

  toggleOptions.item.forEach(element => {
    element.addEventListener('click', toggleNavItems);
  });
})();